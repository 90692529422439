@use "./../../styles/variables.scss" as *;

.hero-container {
  height: calc(100vh - $nav-menu-size);
  width: 100%;
  position: relative;

  @media (max-width: map-get($grid-breakpoints,md)) {
    padding-top: $nav-menu-size;
  }

  --animate-duration: 2500ms !important;
}

.hero-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    user-select: none;
  }
}

.hero-heading {
  line-height: 100%;
  text-align: center;
  font-size: clamp(24px, 4vw, map-get($heading-sizes, h1));
  font-weight: 900;

  @media (min-width: map-get($grid-breakpoints,md)) {
    white-space: nowrap;
  }
}

.hero-text-underline {
  position: relative;
  z-index: 1;
  color: white;
  padding-left: 1em;
  padding-right: 1em;

  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 20px);
    left: calc(-20px);
    height: 100%;
    top: 30%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.underline-variant-hero::before {
    left: 10%;
    width: 80%;
    clip-path: polygon(0% 0%, 95% 0%, 92% 75%, 2% 95%);
  }
  &.underline-variant-1::before {
    left: 60px;
    width: calc(100% - 60px);
    clip-path: polygon(0% 0%, 95% 0%, 92% 75%, 2% 95%);
  }
  &.underline-variant-2::before {
    width: calc(100% + 60px);
    clip-path: polygon(0% 0%, 95% 0%, 98% 85%, 3% 95%);
  }
  &.underline-variant-3::before {
    width: calc(100% + 40px);
    clip-path: polygon(0% 0%, 95% 0%, 98% 85%, 2% 94%);
  }
  &.underline-variant-4::before {
    width: calc(100%);
    clip-path: polygon(0% 0%, 95% 0%, 98% 85%, 3% 95%);
  }
}
