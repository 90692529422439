@use "./../../styles/variables.scss" as *;

.nav-menu-button-container {
  clip-path: polygon(0% 12%, 100% 0%, 100% 92%, 0% 100%);
  transition: all 0.2s ease-out;

  &:hover,
  &:focus-visible {
    background-color: $white;
    outline: none;
  }

  .nav-menu-button {
    margin: 0.3em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    padding-left: clamp(0.5em, 2vw, 2em);
    padding-right: clamp(0.5em, 2vw, 2em);
    // border: 3px solid $primary;
    background-color: $primary;
    color: $white;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    clip-path: polygon(0% 12%, 100% 0%, 100% 92%, 0% 100%);
    transition: 0.2s ease-out;
    text-align: center;
  }

  &.active .nav-menu-button {
    background-color: $black;
  }
}
