@use "./../../styles/variables.scss" as *;

.progress-bar-container {
  position: relative;
  width: 100%;
  height: 30px;

  filter: drop-shadow(0px 0px 12px rgba($black, 0.2));

  z-index: 1;
}

.progress-bar {
  height: 100%;
  z-index: 2;
  position: relative;
  color: $white;
  background-color: $white;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  border: 2px solid $accent;
  overflow: hidden;

  .label {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    padding: 2rem 1rem;
    font-weight: 900;
    z-index: 3;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}

.filled-bar {
  z-index: 3;
  position: relative;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(90deg, $accent, $hyper-accent);
    clip-path: polygon(00% 0%, calc(100% - 1rem) 0%, 100% 100%, 0% 100%);
  }
}
