@use "./../../styles/variables.scss" as *;

.footer-container {
  position: relative;
  z-index: 2;
  color: $white;

  ::selection {
    color: $primary;
    background-color: $white;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0%;
    height: 120%;
    top: 0%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 100%);
  }

  .footer-logo {
    position: absolute;
    width: clamp(200px, 20vw, 300px);
    top: 10%;
    right: 10vw;
    transform: translate(0%, -50%);
    user-select: none;
  }

  @media (max-width: map-get($grid-breakpoints,md)) {
    .footer-logo {
      left: 50%;
      transform: translate(-50%, -70%);
    }
    padding-top: 180px !important;
  }
}
