@use "./../../styles/variables.scss" as *;

.package-offer-container {
  position: relative;
  z-index: 2;

  &.main-package {
    z-index: unset;
  }
}

.package-offer {
  width: min(450px, 80vw);
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding-bottom: 1rem;

  position: relative;
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    left: -1.5rem;
    top: 0%;
    width: calc(100% + 3rem);
    height: 100%;
    pointer-events: none;
    // clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
    // background-color: $black;
  }

  @media (max-width: map-get($grid-breakpoints, xxl)) {
    width: min(400px, 80vw);
  }
  @media (max-width: map-get($grid-breakpoints, xl)) {
    width: min(300px, 80vw);
  }
  // @media (max-width: map-get($grid-breakpoints, lg)) {
  //   width: min(320px, 80vw);
  // }

  .package-title {
    z-index: 5;
  }
}

.package-offer-tail {
  box-sizing: border-box;
  margin-top: -1px;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;

  padding-left: 1rem;
  padding-right: 1rem;
  height: fit-content;
  padding-bottom: 10rem;

  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    clip-path: polygon(0% 0%, 100% 0%, 50% 90%, 50% 90%);
  }
}

.main-package {
  position: relative;

  .package-offer {
    // box-shadow: 0px 0px 20px 20px $dark;
    // box-shadow: 0px -20px 50px 20px $dark;
    box-shadow: 0px 20px 50px 15px $dark;

    // margin-top: -5rem;

    @media (min-width: map-get($grid-breakpoints, xl)) {
      margin-top: -5rem;
    }

    // @media (max-width: map-get($grid-breakpoints, md)) {
    //   margin-top: -5rem;
    // }
  }
  .package-offer,
  .package-offer-tail {
    z-index: 3;
  }
}

.package-red {
  .package-offer,
  .package-offer-tail {
    ::selection {
      color: $primary;
      background-color: $white;
    }
  }

  .package-offer,
  .package-offer::after,
  .package-offer-tail::after {
    color: $white;
    background-color: $primary;
  }

  .package-offer-tail {
    color: $white;
  }

  .main-glow {
    position: absolute;
    z-index: 1;
    width: calc(100% + 3rem);
    height: 70%;
    left: -1.5rem;
    top: 0;
    pointer-events: none;

    // filter: drop-shadow(0px 0px 80px $primary);

    // box-shadow: 0px 0px 40px 20px $primary, inset 0px 0px 40px 20px $primary;
    box-shadow: 0px 0px 420px 20px $primary;
  }

  .package-glow {
    &.package-offer-tail {
      // filter: drop-shadow(0px 0px 80px $primary);
      filter: drop-shadow(0px 40px 80px rgba($primary, 0.7));
    }

    &.package-offer {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: calc(100% + 3rem);
        height: 100%;
        left: -1.5rem;
        top: 0;
        box-shadow: 0px 0px 420px 20px rgba($primary, 0.45);
      }
    }
  }
}

.package-white {
  .package-offer,
  .package-offer::after,
  .package-offer-tail::after {
    color: $primary;
    background-color: $white;
  }

  .package-offer-tail {
    color: $primary;
  }

  .package-glow {
    &.package-offer-tail {
      // filter: drop-shadow(0px 0px 80px $white);
      filter: drop-shadow(0px 40px 80px rgba($white, 0.35));
    }

    &.package-offer {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: calc(100% + 3rem);
        height: 100%;
        left: -1.5rem;
        top: 0;
        box-shadow: 0px 0px 420px 20px rgba($white, 0.45);
      }
    }
  }
}

.price {
  font-size: 2rem;
  white-space: nowrap;
}

.transparent-text {
  opacity: 0.5;
}

.blurred-text {
  filter: blur(4px);
}

.strikeThrough {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 4px;
    transform: rotate(16deg) translate(0%, -50%);
    border-radius: 4px;
    background-color: $black;
  }
}

.ribbon {
  position: absolute;
  top: -22px;
  right: calc(-37px - 1.5rem);
  z-index: 4;
  user-select: none;
  pointer-events: none;
}

.missed-out-point {
  opacity: 0.4;
  text-decoration: line-through;
}
