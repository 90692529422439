@use "./../../styles/variables.scss" as *;

.floating-speakers-list-container {
  z-index: 10;
  position: fixed;
  // height: 100vh;
  height: calc(100vh - 50px);
  top: 0;
  right: 0.5rem;
  padding-top: $nav-menu-size;
  // Size of the scroll arrow
  padding-bottom: calc(80px + 1.5rem);
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    display: none;
  }
}

.speakers-list-container {
  // position: absolute;
  // top: 50%;
  // transform: translate(0%, -50%);

  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 1.5rem;
  gap: 0.2rem;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    display: none;
  }

  .floating-speaker-container {
    &:hover {
      .floating-speaker {
        margin-right: 1rem;
        background-color: $primary;
        box-shadow: 0px 20px 50px 15px #1b1b1b;
      }
    }
  }

  .floating-speaker {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.2s ease-out;
    padding: 1.5rem 1rem;
    clip-path: polygon(0% 5%, 100% 0%, 100% 95%, 0% 100%);
    cursor: pointer;
    user-select: none;
    max-width: 100px;
  }

  .floating-speaker-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding-left: 10px;
    padding-right: 10px;
    pointer-events: none;
  }

  .floating-speaker-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $white;
  }
}

.scroll-speakers-down {
  position: absolute;
  z-index: 10;
  bottom: 0rem;
  width: 100px;
  height: 100px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: url("./../../assets/icons/caret-down-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-out;

  &:hover {
    background: url("./../../assets/icons/caret-down-red.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  // Preload the images
  &:before {
    position: absolute;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    height: 0px;
    width: 0px;
    opacity: 0;
    content: url("./../../assets/icons/caret-down-white.svg") url("./../../assets/icons/caret-down-red.svg");
  }
}

.scroll-speakers-up {
  position: absolute;
  z-index: 10;
  top: -2rem;
  width: 100px;
  height: 100px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: url("./../../assets/icons/caret-up-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-out;

  &:hover {
    background: url("./../../assets/icons/caret-up-white.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  // Preload the images
  &:before {
    position: absolute;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    height: 0px;
    width: 0px;
    opacity: 0;
    content: url("./../../assets/icons/caret-down-white.svg") url("./../../assets/icons/caret-down-red.svg");
  }
}
