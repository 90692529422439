@use "./../../styles/variables.scss" as *;

.map-container {
  height: 60vh;
  width: 100%;
  position: relative;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-bottom: 280px;
  }

  .map-overlay {
    position: absolute;
    top: 50%;
    width: clamp(350px, 30vw, 580px);
    height: auto;
    transform: translate(-20%, -50%);
    z-index: 1;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 20%);
    }
  }
}
