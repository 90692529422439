@use "./../../styles/variables.scss" as *;

.sales-timer-container {
  position: fixed;
  bottom: 0;
  // width: 30%;
  left: 50%;
  transform: translate(-50%, 0%);

  @media (max-width: map-get($grid-breakpoints, xl)) {
    width: 80%;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    width: 100%;
  }

  filter: drop-shadow(0px 4px 24px rgba($black, 0.5));

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $primary, $accent, $primary);
    clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
  }
  z-index: 20;
}

.sales-timer {
  z-index: 21;
  position: relative;
  color: $white;
  gap: 3rem;
  padding: 0.2rem 7rem;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    gap: 2rem;
    padding: 0.2rem 4rem;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    flex-wrap: wrap;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    gap: 1rem;
    padding: 0.2rem 4rem;
  }

  @media (max-width: map-get($grid-breakpoints, xs)) {
    padding: 0.2rem 2rem;
  }

  ::selection {
    background-color: $white;
    color: $primary;
  }
}

.close-banner {
  right: 0rem !important;
  top: 0rem !important;
  padding: 0;
  filter: drop-shadow(0px 0px 8px rgba($black, 1));
}
