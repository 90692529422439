@use "./../../styles/variables.scss" as *;

.speaker-thumbnail-container {
  // width: 260px;
  // height: 370px;

  width: 260px;
  height: auto;
  aspect-ratio: 260/370;

  position: relative;
  z-index: 1;

  @media (max-width: map-get($grid-breakpoints, md)) {
    width: min(260px, 80vw);
  }

  .speaker-thumbnail-background {
    width: calc(100%);
    height: 100%;
    // left: 1px;
    top: 0;
    z-index: -1;
    position: absolute;
    background-color: $primary;
    clip-path: polygon(0% 0%, 100% 0%, 100% 96%, 0% 100%);
  }

  .speaker-image {
    width: 100%;
    height: auto;
    user-select: none;
    transition: 0.3s ease-in-out;
  }

  .speaker-description {
    position: absolute;
    transition: 0.3s ease-in-out;
    margin-left: -1rem;
    bottom: 1rem;
    height: 80px;
  }

  .speaker-behind {
    opacity: 0;
    width: calc(100% - 2rem);
    padding: 1rem;
    height: calc(100% - 3rem);
    padding-bottom: 3rem;
    left: 0;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: 0.3s ease-in-out;
  }

  .speaker-cta {
    width: 300px;
    transform: scale(0.8);
    pointer-events: none;
    touch-action: none;
    a {
      display: block;
      pointer-events: none;
      touch-action: none;
    }
  }

  &.hovering {
    .speaker-image {
      opacity: 0;
    }

    .speaker-description {
      bottom: calc(100% - 100px);
    }

    .speaker-behind {
      opacity: 1;
    }

    .speaker-cta {
      pointer-events: all;
      touch-action: auto;
      a {
        pointer-events: all;
        touch-action: auto;
      }
    }
  }
}
