$primary: #c12026;
$accent: #ee196b;
$hyper-accent: #fa00ff;

$gray: #262626;
$dark: #1b1b1b;
$black: #080606;
$transparent-black: rgba(8, 6, 6, 0.8);
$lt-gray: #717171;
$white-gray: #b1b1b1;
$dirty-white: #fffcfc;
$white: #ffffff;

$success: #3bd100;
$alert: #d10000;

$font-size-base: 16px;
$nav-logo-size: 150px;
$nav-menu-size: 70px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1520px,
);

$container-max-widths: (
  xs: 576px,
  sm: 576px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1420px,
);

// Typography

$heading-sizes: (
  h0: 84px,
  h1: 54px,
  h2: 42px,
  h3: 26px,
  h4: 22px,
  h5: 20px,
);

$paragraph-sizes: (
  p1: 16px,
  p2: 14px,
);
