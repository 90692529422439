@use "./../../styles/variables.scss" as *;

.left-big-shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100svh;
  // height: calc(100svh - $nav-menu-size);
  width: 18vw;
  background-color: $primary;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    left: calc(18vw - 10px);
    top: 0;
    height: 100%;
    width: 300px;
    background-color: $primary;
    clip-path: polygon(0% 0%, 30px 0%, 100% 100%, 0% 100%);
  }
}

.right-image-picture-1 {
  background: url("./../../assets/images/soft-glowing-divider-1-updated.webp");
  background-position: center;
  background-size: cover;
}

.right-image-position-1 {
  position: absolute;
  // V1
  // right: calc(0vw - calc(-10vw / 5vw)vw);

  // V2
  // left: calc(100vw - clamp(300px, 38vw, 780px));
  // top: 0;
  // height: 100vh;
  // width: 0px;
  // padding: 400px 600px;
  // margin: -300px -600px;

  // V3
  // left: calc(100vw - clamp(210px, 25vw, 580px));
  // top: 10%;
  // height: 100vh;
  // width: 0px;
  // padding: 60%;
  // margin: -60%;
  // scale: 0.5;

  // V4
  // left: calc(100vw - clamp(80px, 60vw, 100%));
  // top: -65%;
  // height: 100vh;
  // width: 0px;
  // padding: 100%;
  // margin: -100%;
  // transform: translate(-50%, 50%);
  // scale: 0.3;
  // transform-origin: center right;

  // V5
  left: calc(100vw - clamp(210px, 25vw, 580px));
  top: 10%;
  height: 100vh;
  width: 0px;
  padding: 100vh;
  margin: -100vh;
  scale: 0.5;
  pointer-events: none;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    // top: -10%;
    // left: calc(100vw - clamp(210px, 35vw, 560px));
    // scale: 0.6;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    // left: calc(100vw - clamp(180px, 25vw, 590px));
    left: calc(100vw - clamp(180px, 60vw, 600px));

    top: 0%;
  }
}

.divider-1-container {
  max-width: 60%;
  margin-top: clamp(8rem, 8vw, 10rem);
  padding-left: 5%;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: 8rem;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    // max-width: 80%;
    // padding-left: 10%;
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: center;
    margin-top: 0rem;
  }
}

.divider-1-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 6rem;

  @media (max-width: map-get($grid-breakpoints, md)) {
    gap: 0rem;
    height: 80vh;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}

.divider-1-text {
  position: relative;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: $white;
  margin-top: 6rem;

  &::after {
    content: "";
    position: absolute;
    width: 300px;
    left: -40px;
    height: 60px;
    top: -30%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 0%, 95% 0%, 92% 65%, 7% 95%);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 16px;
    font-weight: 700;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    color: $primary;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    z-index: 1;
    margin-top: 0rem;

    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 4rem);
      left: -2rem;
      height: calc(100% + 3rem);
      top: -1rem;
      z-index: -1;
      background-color: rgba($white, 0.8);
      backdrop-filter: blur(3px);
      clip-path: polygon(0% 0%, 100% 0%, 92% 75%, 7% 95%);
    }
  }
}

.divider-1-action-container {
  position: relative;
  margin-top: 8rem;

  .divider-1-action-button {
    margin-top: 8rem;

    width: 100%;
    max-width: 500px;
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: 5rem;

    .divider-1-action-button {
      margin-top: 5rem;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-top: 20rem;
    text-align: center;

    .divider-1-action-button {
      margin-top: 10rem;
    }
  }
}

.entertainers-area-container {
  max-width: 80%;
  min-height: 100vh;
  margin-top: clamp(3rem, 3vw, 5rem);
  // padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    max-width: 50%;
    margin-top: 2rem;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    // max-width: 60%;
    // padding-left: 10%;

    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    justify-content: center;
    margin-top: 2rem;
  }
}

.entertainers-area-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}

.entertainers-area-text {
  position: relative;

  font-size: map-get($heading-sizes, h1);
  font-weight: 800;
  color: $white;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: map-get($heading-sizes, h2);
    font-weight: 700;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    color: $primary;
    font-size: map-get($heading-sizes, h3);
    font-weight: 700;
    text-align: left;
    z-index: 1;
    margin-top: 3rem;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 4rem);
      left: -2rem;
      height: calc(100% + 3rem);
      top: -1rem;
      z-index: -1;
      background-color: rgba($white, 0.9);
      backdrop-filter: blur(3px);
      clip-path: polygon(0% 0%, 100% 0%, 92% 75%, 7% 95%);
    }
  }
}

.entertainers-area-action-container {
  position: relative;
  margin-top: 5rem;

  .entertainers-area-action-button {
    margin-top: 5rem;

    width: 100%;
    max-width: 500px;
  }

  .entertainers-area-list {
    gap: 5rem;
  }

  @media (max-width: map-get($grid-breakpoints, xxl)) {
    .entertainers-area-list {
      gap: 1rem;
    }
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: 5rem;

    .entertainers-area-action-button {
      margin-top: 5rem;
    }

    .entertainers-area-list {
      gap: 0rem;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    // margin-top: 1rem;
    text-align: center;

    .entertainers-area-action-button {
      margin-top: 1rem;
    }
    .entertainers-area-list {
      gap: 0rem;
    }
  }
}

.divider-2-container {
  max-width: 58%;
  height: 100vh;
  margin-top: clamp(3rem, 3vw, 5rem);
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    max-width: 50%;
    margin-top: 2rem;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    // max-width: 60%;
    // padding-left: 10%;

    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    justify-content: center;
    margin-top: 2rem;
  }
}

.divider-2-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}

.divider-2-text {
  position: relative;

  font-size: map-get($heading-sizes, h1);
  font-weight: 800;
  color: $white;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: map-get($heading-sizes, h2);
    font-weight: 700;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    color: $primary;
    font-size: map-get($heading-sizes, h3);
    font-weight: 700;
    text-align: left;
    z-index: 1;
    margin-top: 3rem;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 4rem);
      left: -2rem;
      height: calc(100% + 3rem);
      top: -1rem;
      z-index: -1;
      background-color: rgba($white, 0.9);
      backdrop-filter: blur(3px);
      clip-path: polygon(0% 0%, 100% 0%, 92% 75%, 7% 95%);
    }
  }
}

.divider-2-action-container {
  position: relative;
  margin-top: 5rem;

  .divider-2-action-button {
    margin-top: 5rem;

    width: 100%;
    max-width: 500px;
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: 5rem;

    .divider-2-action-button {
      margin-top: 5rem;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    // margin-top: 1rem;
    text-align: center;

    .divider-2-action-button {
      margin-top: 1rem;
    }
  }
}

.right-image-picture-2 {
  background: url("./../../assets/images/soft-glowing-entertainers-area-red.webp");
  background-position: center;
  background-size: cover;
}

.right-image-picture-3 {
  background: url("./../../assets/images/soft-glowing-divider-2-updated.webp");
  background-position: center;
  background-size: cover;
}

.right-image-position-2 {
  position: absolute;
  // V5
  left: calc(100vw - clamp(210px, 25vw, 580px));
  top: 0%;
  height: 100vh;
  width: 0px;
  padding: 100vh;
  margin: -100vh;
  scale: 0.5;
  pointer-events: none;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    // top: -10%;
    // left: calc(100vw - clamp(210px, 35vw, 560px));
    // scale: 0.6;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    left: calc(100vw - clamp(180px, 60vw, 600px));
    top: 0%;
  }
}

.special-word-break-1 {
  max-width: 20ch;
}

.special-word-break-2 {
  max-width: 75ch;
}

.stats-divider-image {
  background: url("./../../assets/images/stats-divider.webp");

  @media (max-width: map-get($grid-breakpoints, md)) {
    background: url("./../../assets/images/stats-divider-md.webp");
    background-position: center;
  }

  background-position: center;
  background-size: cover;
  clip-path: polygon(0% 0%, 100% 10%, 100% 95%, 0% 100%);
}

.stats-divider {
  position: relative;
  justify-content: space-between;

  &::after {
    content: "";
    position: absolute;
    width: 110%;
    left: -5%;
    height: 50%;
    top: 35%;
    z-index: -1;
    background-color: #c12026;
    clip-path: polygon(0% 0%, 100% 0%, 97% 95%, 0.5% 30%);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    justify-content: center;

    &::after {
      display: none;
    }
  }
}

.impact-shape-1 {
  position: relative;
  color: $white;
  max-width: 60%;

  &::after {
    content: "";
    position: absolute;
    width: 75%;
    left: -10%;
    height: 120%;
    top: 0%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 15%, 100% 20%, 94% 75%, 7% 95%);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    max-width: 85%;

    &::after {
      width: 80%;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    &::after {
      width: 96%;
      left: 2%;
    }
  }
}

.impact-shape-2 {
  position: relative;
  color: $white;
  max-width: 60%;
  text-align: right;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 10%;
    height: 120%;
    top: 0%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(8% 25%, 100% 11%, 94% 94%, 15% 85%);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    max-width: 85%;

    &::after {
      width: 80%;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    &::after {
      width: 96%;
      left: 2%;
    }
  }
}

.impact-shape-3 {
  position: relative;
  color: $white;
  max-width: 60%;

  &::after {
    content: "";
    position: absolute;
    width: 75%;
    left: -10%;
    height: 120%;
    top: 0%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 15%, 100% 20%, 84% 65%, 3% 85%);
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    max-width: 85%;

    &::after {
      width: 80%;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    &::after {
      width: 96%;
      left: 2%;
    }
  }
}

.wrapped-section-tile-1 {
  position: relative;
  z-index: 1;
  color: white;
  padding-left: 1em;
  padding-right: 1em;

  &::after {
    content: "";
    position: absolute;
    width: 98%;
    left: 2%;
    height: 120%;
    top: -10%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 0%, 95% 10%, 92% 95%, 6% 95%);
  }
}

.cta-divider-1 {
  position: relative;
  z-index: 1;
  color: white;
  padding-top: 10rem;
  padding-bottom: 10rem;
  // background-color: $primary;
  ::selection {
    color: $primary;
    background-color: $white;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0%;
    height: 100%;
    top: 0%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
  }
}

.speaker-quote {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  // background-color: $primary;
  min-height: 320px;

  ::selection {
    color: $primary;
    background-color: $white;
  }

  .quote-media-container {
    height: 100%;
  }

  .quote-image {
    height: 100%;
    user-select: none;
  }

  .quote-video {
    padding-left: 569px;
    padding-top: 320px;
    height: 100%;
    width: 100%;
    position: relative;
    box-sizing: border-box;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding-left: min(569px, 100%);
      padding-top: min(calc(100vw * 9 / 16), 320px);
    }
  }

  .content {
    z-index: 3;
    padding: 3rem;
    position: relative;
    background-color: $primary;

    &::after {
      content: url("./../../assets/images/ribbons/quote-marks.svg");
      position: absolute;
      top: -2rem;
      right: 2rem;
      opacity: 0.8;
      font-size: 90px;
    }
  }

  // @media (max-width: map-get($grid-breakpoints, md)) {
  // }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;

    .quote-video {
      padding-top: 110%;
    }
    .quote-media-container,
    .quote-image {
      width: 100%;
    }
    .content {
      padding: 3rem;
      padding-bottom: 8rem;
      margin-top: -4rem;
      pointer-events: none;
    }
  }
}

.quote-container {
  display: grid;

  .quote-wrapper {
    width: 80%;
    position: relative;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      width: 90%;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }

    // @media (max-width: map-get($grid-breakpoints, sm)) {
    //   width: 100vw;
    // }
  }

  .quote-left {
    .content::after {
      top: -1rem;
      right: 4rem;
    }

    justify-self: flex-start;
    .speaker-quote,
    .speaker-quote-glow-red::after {
      clip-path: polygon(1% 1%, 100% 6%, 100% 94%, 1% 99%);
      flex-direction: row;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      .speaker-quote {
        flex-wrap: wrap;
      }
      .content::after {
        opacity: 1;
        top: -1rem;
        right: 2rem;
      }
    }
  }

  .quote-right {
    .content::after {
      top: -2rem;
      right: 3rem;
    }

    justify-self: flex-end;
    .speaker-quote,
    .speaker-quote-glow-red::after {
      clip-path: polygon(0% 6%, 99% 1%, 99% 99%, 0% 94%);
      flex-direction: row-reverse;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      .speaker-quote {
        flex-wrap: wrap;
      }
      .content::after {
        opacity: 1;
        top: -1rem;
        right: 2rem;
      }
    }
  }

  .speaker-quote-glow-red {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    filter: drop-shadow(0px 0px 50px rgba($primary, 0.8));

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary;
      // transform: scaleY(0.9);
      transform-origin: center;
      pointer-events: none;
    }
  }
}

.cta-divider-2 {
  position: relative;
  z-index: 1;
  color: white;
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: $primary;
  clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
  text-align: start;
  ::selection {
    color: $primary;
    background-color: $white;
  }

  .cta-divider-2-content {
    max-width: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0%;
    height: 100%;
    top: 0%;
    z-index: -1;
    background: url("./../../assets/images/divider-people.webp");
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .cta-divider-2-content {
      max-width: 100%;
    }
  }
}

.cta-divider-3 {
  position: relative;
  z-index: 1;
  color: white;
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: $primary;
  clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
  text-align: start;
  ::selection {
    color: $primary;
    background-color: $white;
  }

  .cta-divider-2-content {
    max-width: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0%;
    height: 100%;
    top: 0%;
    z-index: -1;
    background: url("./../../assets/images/divider-3.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .cta-divider-2-content {
      max-width: 100%;
    }
  }
}

.wrapped-section-tile-2 {
  position: relative;
  z-index: 1;
  color: $primary;
  padding-left: 1em;
  padding-right: 1em;

  &::after {
    content: "";
    position: absolute;
    width: 98%;
    left: 2%;
    height: 120%;
    top: -10%;
    z-index: -1;
    background-color: $white;
    clip-path: polygon(0% 40%, 100% 0%, 95% 100%, 5% 100%);
  }
}

.wrapped-section-tile-3 {
  position: relative;
  z-index: 1;
  color: $white;
  padding-left: 1em;
  padding-right: 1em;

  &::after {
    content: "";
    position: absolute;
    width: 98%;
    left: 2%;
    height: 120%;
    top: -10%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 40%, 100% 0%, 95% 100%, 5% 100%);
  }
}
