@use "variables.scss" as *;

.container {
  margin: auto;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: map-get($container-max-widths, $key);
    }
  }
}

.big-container {
  margin: auto;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: calc(map-get($container-max-widths, $key) + 30vw);
    }
  }
}

.small-container {
  margin: auto;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: calc(map-get($container-max-widths, $key) - 8vw);
    }
  }
}

@for $i from 1 through 7 {
  $size-increment: 0.8rem;

  .gap-#{$i} {
    gap: calc($size-increment * $i * $i/3) !important;
  }
}

@for $i from 0 through 7 {
  $size-increment: 0.8rem;

  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      .m-#{$key}-#{$i} {
        margin: calc($size-increment * $i * $i/3) !important;
      }
      .mt-#{$key}-#{$i} {
        margin-top: calc($size-increment * $i * $i/3) !important;
      }
      .ml-#{$key}-#{$i} {
        margin-left: calc($size-increment * $i * $i/3) !important;
      }
      .mr-#{$key}-#{$i} {
        margin-right: calc($size-increment * $i * $i/3) !important;
      }
      .mb-#{$key}-#{$i} {
        margin-bottom: calc($size-increment * $i * $i/3) !important;
      }

      .p-#{$key}-#{$i} {
        padding: calc($size-increment * $i * $i/3) !important;
      }
      .pt-#{$key}-#{$i} {
        padding-top: calc($size-increment * $i * $i/3) !important;
      }
      .pl-#{$key}-#{$i} {
        padding-left: calc($size-increment * $i * $i/3) !important;
      }
      .pr-#{$key}-#{$i} {
        padding-right: calc($size-increment * $i * $i/3) !important;
      }
      .pb-#{$key}-#{$i} {
        padding-bottom: calc($size-increment * $i * $i/3) !important;
      }
    }
  }

  .m-#{$i} {
    margin: calc($size-increment * $i * $i/3) !important;
  }
  .mt-#{$i} {
    margin-top: calc($size-increment * $i * $i/3) !important;
  }
  .ml-#{$i} {
    margin-left: calc($size-increment * $i * $i/3) !important;
  }
  .mr-#{$i} {
    margin-right: calc($size-increment * $i * $i/3) !important;
  }
  .mb-#{$i} {
    margin-bottom: calc($size-increment * $i * $i/3) !important;
  }

  .p-#{$i} {
    padding: calc($size-increment * $i * $i/3) !important;
  }
  .pt-#{$i} {
    padding-top: calc($size-increment * $i * $i/3) !important;
  }
  .pl-#{$i} {
    padding-left: calc($size-increment * $i * $i/3) !important;
  }
  .pr-#{$i} {
    padding-right: calc($size-increment * $i * $i/3) !important;
  }
  .pb-#{$i} {
    padding-bottom: calc($size-increment * $i * $i/3) !important;
  }
}

.height-30px {
  height: 30px;
}

.w-100 {
  width: 100% !important;
}

.w-fit {
  width: fit-content;
}

.w-100vw {
  height: 100vw !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-100vw {
  max-width: 100vw !important;
}

.h-100 {
  height: 100% !important;
}

.h-fit {
  height: fit-content;
}

.h-100vh {
  height: 100vh !important;
}

.max-h-100 {
  max-height: 100% !important;
}

.max-h-100vh {
  max-height: 100vh !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@each $key, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .text-#{$key}-center {
      text-align: center;
    }
    .text-#{$key}-left {
      text-align: left;
    }
    .text-#{$key}-right {
      text-align: right;
    }
  }
}

.text-black {
  color: $black !important;
}

.text-primary {
  color: $primary !important;
}

.text-accent {
  color: $accent !important;
}

.text-white {
  color: $white !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

.p-static {
  position: static !important;
}

.p-relative {
  position: relative !important;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

@each $key, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .d-#{$key}-none {
      display: none;
    }
    .d-#{$key}-flex {
      display: flex;
    }
    .d-#{$key}-block {
      display: block;
    }
    .p-#{$key}-relative {
      position: relative !important;
    }
    .p-#{$key}-static {
      position: static !important;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.responsive-rows {
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 600px) {
    .flex-1:nth-child(2n) {
      flex-basis: 100%;
    }
  }
}

.flex-1 {
  flex: 1;
}

.min-width-100 {
  min-width: 100px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.rotate-90 {
  transform: rotate(90deg);
}

.no-decoration {
  text-decoration: none;
}

.no-selection {
  user-select: none;
}

//
// Typography
//

@each $fontName, $fontSize in $paragraph-sizes {
  .#{$fontName} {
    font-size: #{$fontSize};
    font-weight: 600;
  }
}

@each $key, $breakpoint in $grid-breakpoints {
  @each $fontName, $fontSize in $paragraph-sizes {
    .#{$fontName}-#{$key} {
      @media (min-width: $breakpoint) {
        font-size: #{$fontSize};
        font-weight: 600;
      }
    }
  }
}

.font-selling-points {
  font-size: 34px;
  font-weight: 900;
  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 22px;
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 26px;
  }
}

@each $fontName, $fontSize in $heading-sizes {
  .#{$fontName} {
    font-size: #{$fontSize};
    font-weight: 900;
  }
}
@each $key, $breakpoint in $grid-breakpoints {
  @each $fontName, $fontSize in $heading-sizes {
    .#{$fontName}-#{$key} {
      @media (min-width: $breakpoint) {
        font-size: #{$fontSize};
        font-weight: 900;
      }
    }
  }
}

.font-thin {
  font-weight: 100;
}
.font-normal {
  font-weight: 500;
}
.font-semi-bold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-black {
  font-weight: 900;
}

@each $key, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .font-semi-bold-#{$key} {
      font-weight: 600;
    }
    .font-bold-#{$key} {
      font-weight: 700;
    }
    .font-black-#{$key} {
      font-weight: 900;
    }
  }
}

.avoid-wrap {
  display: inline-block;
}

.custom-input {
  padding: 1rem;
  border: 4px solid $white;
  transition: border ease-out 0.3s;
  outline: none;

  &:focus-visible,
  &:focus-within,
  &:active,
  &:focus {
    border: 4px solid $lt-gray;
  }

  &:hover {
    border: 4px solid $dark;
  }
}

.custom-checkbox-container {
  display: block;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  padding-left: 42px;
  margin-bottom: 12px;
  padding-top: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox {
  position: relative;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  left: 0;
  cursor: pointer;
  height: 100%;
  // width: 100%;
}

// Custom radio
input[type="radio"] {
  appearance: none;
  border-radius: 100%;

  width: 1.6rem;
  height: 1.6rem;

  border: 2px solid $white;
  transition: all linear 0.2s;
}

.styled-radio {
  border: none;

  input[type="radio"] {
    margin: 0;
    position: absolute;
    transform: translate(calc(-100% - 12px), -50%);
    top: 50%;
    left: 1.6rem;
    height: 25px;
    width: 25px;
    border: 2px solid $white;
    cursor: pointer;
  }

  input[type="radio"]:checked {
    border: 0.8rem solid $white !important;
  }

  label {
    &:hover {
      // background: lighten($primary, 15%);
      input {
        background: lighten($primary, 20%);
      }
    }
    cursor: pointer;
    transition: all linear 0.2s;
    // padding: 0rem 1.6rem;
    padding-left: 1.6rem;
    line-height: 1.6rem;
    border-top-right-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;

    // padding: 1rem 1rem 1rem 3rem;
    // background-color: lighten($white, 44%);
    // border: 1px solid lighten($white, 44%);

    // &:hover {
    //   border: 1px solid lighten($white, 20%);
    // }

    // &:has(input[type="radio"]:checked) {
    //   border: 1px solid lighten($white, 0%);
    // }
  }
}

/* Create a custom checkbox */
.custom-checkmark {
  position: absolute;
  transform: translate(calc(-100% - 12px), -50%);
  top: 50%;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  // background: lighten($primary, 25%);
  border: 2px solid $white;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .custom-checkmark {
  background-color: lighten($primary, 10%);
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .custom-checkmark {
  background: $primary;
}

/* Create the custom-checkmark/indicator (hidden when not checked) */
.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the custom-checkmark when checked */
.custom-checkbox-container input:checked ~ .custom-checkmark:after {
  display: block;
}

/* Style the custom-checkmark/indicator */
.custom-checkbox-container .custom-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.customInput {
  position: relative;
  box-sizing: border-box;
  background: $white;
  border: 2px solid $white;
  color: $black;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 100%;
  transition: all ease-out 0.4s;
  outline: transparent !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 2px solid lighten($dark, 0%);
  }
  &:hover {
    background: lighten($primary, 55%);
    border: 2px solid lighten($dark, 20%);
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    border: 2px solid lighten($dark, 1);
  }
}

.invisible {
  opacity: 0;
  position: absolute;
}

.invisible-no-interaction {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.no-interaction {
  pointer-events: none;
  user-select: none;
}

.disabled {
  user-select: none !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

b {
  font-weight: 900;
}

table,
th,
td {
  border: 1px solid $white;
  border-collapse: collapse;
  padding: 1rem;
}

.box-sizing-border {
  box-sizing: border-box;
}
