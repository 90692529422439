@use "./../../styles/variables.scss" as *;

.supreme-wrapper {
  position: relative;
  margin-left: 3rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10rem;
    width: calc(100% + 10rem);
    height: 100%;
    background: linear-gradient($primary, $accent);
    opacity: 0.6;
    filter: blur(90px);

    @media (max-width: map-get($grid-breakpoints, xl)) {
      left: -5rem;
      width: calc(100% + 5rem);
    }

    // @media (max-width: map-get($grid-breakpoints, md)) {
    //   left: -5rem;
    //   width: calc(50% + 10rem);
    // }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient($primary, $accent);
    clip-path: polygon(0% 0%, 100% 8%, 100% 92%, 0% 100%);
  }
}

.supreme-package-offer {
  .supreme-package-content {
    width: 100%;
    z-index: 2;
  }
  width: 100%;
  box-sizing: border-box;
  padding-top: 3rem;
  padding-left: 12rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  color: $white;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    padding-left: 6rem;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-left: 2rem;
  }

  ::selection {
    color: $primary;
    background-color: $white;
  }
}

.is-apple-device {
  .supreme-3d-object {
    transform: translate(-100%, 0%) scale(1);
  }
}

.supreme-3d-object {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  user-select: none;
  transform: translate(-100%, 0%) scale(1.08);
  transform-origin: center center;

  video,
  img {
    height: 100% !important;
    width: auto;
  }

  img {
    transform: translate(-2.5%, 0%) scale(1);
  }

  @media (max-width: map-get($grid-breakpoints, xxl)) {
    img {
      transform: translate(-20.6%, 0%) scale(1) !important;
    }
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    canvas,
    video,
    img {
      scale: 0.8;
    }
    img {
      transform: translate(-31.5%, 0%) scale(0.8) !important;
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    img {
      transform: translate(-66.3%, 0%) scale(0.6) !important;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    canvas,
    video,
    img {
      scale: 0.6;
    }
    img {
      display: none !important;
    }
  }
}

.bullet-points-wrapper {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    flex-wrap: wrap;
  }

  .bullet-points-column {
    flex-basis: 1;
  }
}
