.marker-container {
  // transform: translateY(-100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  user-select: none;

  .marker-image {
    pointer-events: none;
    user-select: none;
  }
}
