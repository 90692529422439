@use "./../../styles/variables.scss" as *;

.stuff-main-container {
  margin: auto;
  max-width: 800px;
}

.expandable-polygon-container {
  display: flex;

  &.left-side {
    justify-content: flex-start;

    .expandable-polygon-wrapper {
      text-align: left;
      justify-content: flex-start;
    }
  }

  &.right-side {
    justify-content: flex-end;

    .expandable-polygon-wrapper {
      text-align: right;
      justify-content: flex-end;
    }
  }
}

.expandable-polygon-wrapper {
  width: 70%;
  position: relative;
  height: 200px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  overflow: hidden;

  display: flex;
  align-items: center;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    width: 100%;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    &:hover {
      .expandable-polygon {
        max-width: 100%;
        height: 100%;

        &.expandable-polygon-left {
          .image-inside {
            transform: translateX(-108%);
          }
        }

        &.expandable-polygon-right {
          .image-inside {
            transform: translateX(0%);
          }
        }
      }

      .expandable-polygon-left {
        clip-path: polygon(0% 0%, 95% 0%, 92% 75%, 5% 90%);
      }

      .expandable-polygon-right {
        clip-path: polygon(0% 15%, 100% 0%, 98% 80%, 5% 80%);
      }
    }
  }

  &.polygon-red {
    .polygon-text {
      color: $white;
      ::selection {
        color: $primary;
        background-color: $white;
      }
    }
    .expandable-polygon {
      background: $primary;
    }
  }

  &.polygon-white {
    .polygon-text {
      color: $dark;
    }
    .expandable-polygon {
      background: $white;
    }
  }
}

.expandable-polygon {
  transition: all 0.6s ease-in-out;
  height: 100%;
  /* padding: 24px 48px; */
  z-index: 3;
  position: relative;
  max-width: 600px;

  padding-left: 3.5rem;

  clip-path: polygon(0% 0%, 95% 20%, 92% 75%, 10% 95%);
  overflow: hidden;
  box-sizing: border-box;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;

  &.expandable-polygon-left {
    clip-path: polygon(0% 0%, 95% 20%, 92% 75%, 10% 95%);
    left: 0;
  }

  &.expandable-polygon-right {
    clip-path: polygon(0% 15%, 100% 0%, 92% 90%, 5% 80%);
    right: 0;
  }
}

.polygon-text {
  padding: 24px 48px;
  z-index: 3;
  position: relative;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-inside {
  position: absolute;
  left: 100%;
  top: 0px;
  height: 100%;
  width: auto;
  transition: transform 0.6s ease-in-out, width 0.4s ease-in-out, opacity 0.1s linear;
}

.left-side {
  .polygon-text {
    align-items: flex-start;
    text-align: left;
  }
  .image-inside {
    left: 100%;
    top: 0px;
  }
}

.right-side {
  .polygon-text {
    align-items: flex-end;
    text-align: right;
  }
  .image-inside {
    left: 0%;
    transform: translateX(-100%);
    top: 0px;
  }
}
