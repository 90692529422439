@use "./variables.scss" as *;
@use "./styleguides.scss" as *;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-weight: 600;
  font-size: $font-size-base;
  background-color: $black;

  // cursor: url("./../assets/cursors/default-white.cur"), auto;
  // cursor: url("./../assets/cursors/text-white.cur"), text;
}

::-moz-selection {
  /* Code for Firefox */
  color: $white;
  background: $primary;
}

::selection {
  color: $white;
  background: $primary;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: none;
  color: $white;
}

body::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 2px;
  border: 3px solid $black;
}

a,
.a-styling {
  text-decoration: none !important;
  color: $white;
  cursor: pointer;

  &:hover {
    color: $black;
  }
}

.link-1 {
  text-decoration: none !important;
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.reveal-on-scroll {
  animation-name: none !important;
}

.animate__animated {
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important;
}

.text-underline {
  position: relative;
  z-index: 1;
  color: white;
  padding-left: 1em;
  padding-right: 1em;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0%;
    height: 100%;
    top: 0%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.text-underline-black {
  color: white;

  &::after {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.text-underline-primary {
  color: white;
  width: fit-content;

  &::after {
    background-color: $primary;
  }
}

.text-underline-white {
  color: $primary;

  &::after {
    background-color: $white;
  }
}

// .font-selling-points:nth-child(2){
//   transition-delay: 200ms;
// }
// .font-selling-points:nth-child(3){
//   transition-delay: 400ms;
// }
// .font-selling-points:nth-child(4){
//   transition-delay: 600ms;
// }
// .font-selling-points:nth-child(5){
//   transition-delay: 800ms;
// }

.underline-variant-1::after {
  width: calc(100% + 20px);
  left: calc(-20px);
  height: 100%;
  top: 30%;
  clip-path: polygon(0% 0%, 95% 0%, 92% 75%, 2% 95%);
}
.underline-variant-2::after {
  width: 110%;
  left: -5%;
  height: 100%;
  top: 30%;
  clip-path: polygon(0% 0%, 95% 0%, 98% 85%, 3% 95%);
}
.underline-variant-3::after {
  width: calc(100% + 20px);
  left: calc(-20px);
  height: 100%;
  top: 30%;
  clip-path: polygon(0% 0%, 95% 10%, 98% 75%, 2% 94%);
}
.underline-variant-4::after {
  width: calc(100% + 20px);
  left: calc(-20px);
  height: 100%;
  top: 30%;
  clip-path: polygon(0% 10%, 95% 0%, 98% 85%, 7% 80%);
}
.underline-variant-5::after {
  width: 120%;
  clip-path: polygon(5% 0%, 100% 35%, 100% 90%, 0% 100%);
}
.underline-variant-6::after {
  width: 120%;
  left: -20%;
  height: 120%;
  top: -10%;
  clip-path: polygon(1% 40%, 98% 0%, 100% 100%, 0% 90%);
}
.underline-variant-7::after {
  width: 100%;
  left: 0%;
  height: 120%;
  top: -10%;
  clip-path: polygon(1% 0%, 95% 0%, 100% 100%, 0% 100%);
}
.underline-variant-8::after {
  width: 120%;
  left: -10%;
  height: 190%;
  top: -50%;
  clip-path: polygon(0% 10px, 100% 0%, 100% 100%, 0% 100%);
}
.underline-variant-9::after {
  width: 100%;
  left: 0%;
  height: 140%;
  top: -20%;
  clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%);
  background-color: $black;
}
.underline-variant-10::after {
  width: 100%;
  left: 0%;
  height: 120%;
  top: -10%;
  clip-path: polygon(5% 0%, 95% 10%, 100% 90%, 0% 100%);
}
.underline-variant-11::after {
  width: 110%;
  left: -5%;
  height: 120%;
  top: -10%;
  clip-path: polygon(2% 25%, 99% 0%, 100% 100%, 0% 90%);
}

.text-soft-glow-white {
  position: relative;
  &::after {
    content: attr(glow-text);
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(80px);
    z-index: -1;
    color: $white;
    opacity: 0.4;
    transform: scale(1.15);

    -webkit-text-shadow: 0px 0px 200px $white;
    -moz-text-shadow: 0px 0px 200px $white;
    text-shadow: 0px 0px 200px $white;
  }
}

.text-hard-glow-white {
  position: relative;
  &::after {
    content: attr(glow-text);
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(80px);
    z-index: -1;
    color: $white;
    opacity: 0.4;
    transform: scale(1.4);

    -webkit-text-shadow: 0px 0px 200px $white;
    -moz-text-shadow: 0px 0px 200px $white;
    text-shadow: 0px 0px 200px $white;
  }
}

.text-soft-glow-red {
  position: relative;

  &::after {
    content: attr(glow-text);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(80px);
    transform: scale(1.8);
    z-index: -2;
    color: $primary;
    opacity: 0.8;

    -webkit-text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
    -moz-text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
    text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
  }
}

.text-hard-glow-red {
  position: relative;

  &::after {
    content: attr(glow-text);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(80px);
    transform: scale(1.8);
    z-index: -2;
    color: $primary;

    -webkit-text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
    -moz-text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
    text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
  }
}

.object-softer-glow-white {
  filter: drop-shadow(0px 0px 50px rgba($white, 0.3));
}

.object-soft-glow-white {
  // box-shadow: 0px 0px 40px 20px #fff, inset 0px 0px 40px 20px #fff;
  filter: drop-shadow(0px 0px 60px rgba($white, 0.6));
}

.object-hard-glow-white {
  // box-shadow: 0px 0px 40px 20px #fff, inset 0px 0px 40px 20px #fff;
  filter: drop-shadow(0px 0px 80px $white);
}

.object-softer-glow-red {
  filter: drop-shadow(0px 0px 50px rgba($primary, 0.7));
}

.object-soft-glow-red {
  filter: drop-shadow(0px 0px 40px $primary);
}

.object-hard-glow-red {
  filter: drop-shadow(0px 0px 120px $primary);
}

.clone-glow {
  z-index: -1;
  position: absolute;
  filter: blur(100px);
}

@each $key, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .text-soft-glow-white-#{$key} {
      position: relative;
      &::after {
        content: attr(glow-text);
        position: absolute;
        left: 0;
        top: 0;
        filter: blur(80px);
        z-index: -1;
        color: $white;
        opacity: 0.4;
        transform: scale(1.15);

        -webkit-text-shadow: 0px 0px 200px $white;
        -moz-text-shadow: 0px 0px 200px $white;
        text-shadow: 0px 0px 200px $white;
      }
    }

    .text-hard-glow-white-#{$key} {
      position: relative;
      &::after {
        content: attr(glow-text);
        position: absolute;
        left: 0;
        top: 0;
        filter: blur(80px);
        z-index: -1;
        color: $white;
        opacity: 0.4;
        transform: scale(1.4);

        -webkit-text-shadow: 0px 0px 200px $white;
        -moz-text-shadow: 0px 0px 200px $white;
        text-shadow: 0px 0px 200px $white;
      }
    }

    .text-glow-red-#{$key} {
      position: relative;

      &::after {
        content: attr(glow-text);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        filter: blur(80px);
        transform: scale(1.8);
        z-index: -2;
        color: $primary;

        -webkit-text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
        -moz-text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
        text-shadow: 0px 0px 100px $primary, 0px 0px 100px $primary;
      }
    }

    .object-soft-glow-white-#{$key} {
      filter: drop-shadow(0px 0px 60px rgba($white, 0.6));
    }

    .object-hard-glow-white-#{$key} {
      filter: drop-shadow(0px 0px 80px $white);
    }

    .object-soft-glow-red-#{$key} {
      filter: drop-shadow(0px 0px 40px $primary);
    }

    .object-hard-glow-red-#{$key} {
      filter: drop-shadow(0px 0px 120px $primary);
    }

    .clone-glow-#{$key} {
      z-index: -1;
      position: absolute;
      filter: blur(100px);
    }
  }
}

.bullet-point-left {
  padding-left: 2rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: currentColor;
    left: 0rem;
    top: 50%;
    transform: translateY(-50%);
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 1rem;
  }
}

.bullet-point-right {
  padding-right: 2rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: currentColor;
    right: 0rem;
    top: 50%;
    transform: translateY(-50%);
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 1rem;
  }
}

.subscribe-email-form {
  width: min(100vw, 500px);
  // width: 100%;
  box-sizing: border-box;

  input {
    box-sizing: border-box;
  }
}

.all-unset {
  all: unset;
}

button {
  all: unset;
}
.button {
  padding: 1rem 3rem;

  position: relative;
  text-align: center;
  transition: all 0.2s ease-out;
  width: 120px;
  // padding: 0.8rem 1.2rem;
  cursor: pointer;
  user-select: none;
  text-align: center;
  filter: drop-shadow(0px 0px 1px rgba($white, 0));
  font-size: 18px;
  font-weight: 700;
  color: $white;

  // The actual background
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: $primary;
    z-index: -2;
    clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
    transition: 0.3s background-color ease-out, 0.3s color ease-out, 0.3s filter ease-out;
  }

  // The animated "border"
  &::before {
    transition: all 0.3s ease-out;
    content: "";
    z-index: -2;
    position: absolute;
    width: 100%;
    left: 0%;
    height: 100%;
    top: 0%;
    clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
    background-color: $white;
    opacity: 0;
  }

  &:hover,
  &:active,
  &:focus-visible {
    filter: drop-shadow(0px 0px 80px rgba($white, 0.7));
    &::after {
      background-color: $black;
    }

    &::before {
      width: calc(100% + 0.8rem);
      left: calc(0% - 0.4rem);
      height: calc(100% + 0.8rem);
      top: calc(0% - 0.4rem);
      opacity: 1;
    }
  }
}

.confirm-button {
  color: $dark;
  &::after {
    background-color: $white;
  }

  &:hover,
  &:active,
  &:focus-visible {
    color: $white;

    &::after {
      background-color: $dark;
    }

    &::before {
      background-color: $white;
    }
  }
}

.close-icon {
  padding: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  box-sizing: content-box;
  background: url("./../assets/icons/close-icon-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  transition: all 0.2s ease-out;
  z-index: 999;

  &:hover {
    background: url("./../assets/icons/close-icon-black.svg");
    background-size: 1.5rem 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
  }

  // Preload the images
  &:before {
    position: absolute;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    height: 0px;
    width: 0px;
    opacity: 0;
    background-size: 1.5rem 1.5rem;
    content: url("./../assets/icons/close-icon-white.svg") url("./../assets/icons/close-icon-black.svg");
  }
}

@keyframes closeModal {
  0% {
    margin-top: 0vh;
    opacity: 1;
    display: block;
  }
  100% {
    margin-top: 100vh;
    opacity: 0;
    display: none;
  }
}

@keyframes openModal {
  0% {
    margin-top: 100vh;
    opacity: 0;
    display: none;
  }
  100% {
    margin-top: 0vh;
    opacity: 1;
    display: block;
  }
}

.remove-after-glows {
  &::after {
    display: none !important;
  }
}

.remove-filter-glows {
  filter: none !important;
}

.ghost-over {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

#rcc-decline-button {
  cursor: pointer;
}
.cookie-decline-button {
  margin-right: 6rem;
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .CookieConsent {
    & > div:first-of-type {
      margin: 8px !important;
    }
  }
  #rcc-confirm-button {
    width: 100vw !important;
    display: block !important;
    text-align: center !important;

    .cookie-confirm-button {
      margin-top: -0.5rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  #rcc-decline-button {
    width: 100vw !important;
    display: block !important;
    text-align: center !important;

    .cookie-decline-button {
      width: 100%;
      margin-right: 0rem;
    }
  }
}
