@use "./../../styles/variables.scss" as *;

.floating-items-list-container {
  z-index: 10;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 2vw;
  padding-top: calc($nav-menu-size + $nav-logo-size/2);
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: map-get($grid-breakpoints, xxl)) {
    display: none;
  }
}

.items-list-container {
  // position: absolute;
  // top: 50%;
  // transform: translate(0%, -50%);

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 0.2rem;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    display: none;
  }

  .floating-item {
    position: relative;
    text-align: center;
    transition: all 0.2s ease-out;
    width: 120px;
    padding: 0.8rem 1.2rem;
    cursor: pointer;
    user-select: none;
    text-align: center;
    color: $white;
    filter: drop-shadow(0px 0px 1px rgba($white, 0));
    font-size: 18px;
    font-weight: 700;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background-color: $primary;
      z-index: -2;
      clip-path: polygon(0% 12%, 100% 0%, 100% 88%, 0% 100%);
      transition: 0.3s background-color ease-out, 0.3s color ease-out, 0.3s filter ease-out;
    }

    &::before {
      transition: all 0.3s ease-out;
      content: "";
      z-index: -2;
      position: absolute;
      width: 100%;
      left: 0%;
      height: 100%;
      top: 0%;
      clip-path: polygon(0% 12%, 100% 0%, 100% 88%, 0% 100%);
      background-color: $white;
      opacity: 0;
    }
  }

  .floating-item-container {
    &:hover,
    &:active,
    &:focus-visible {
      .floating-item {
        filter: drop-shadow(0px 0px 80px rgba($white, 0.7));
        &::after {
          background-color: $black;
        }

        &::before {
          width: calc(100% + 0.8rem);
          left: calc(0% - 0.4rem);
          height: calc(100% + 0.8rem);
          top: calc(0% - 0.4rem);
          opacity: 1;
        }
      }
    }
  }

  .floating-item-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    pointer-events: none;
  }

  .floating-item {
    &.active {
      margin-top: 1rem;
      margin-bottom: 1rem;
      &::after {
        background-color: $primary;
      }
      &::before {
        width: calc(100% + 0.8rem);
        left: calc(0% - 0.4rem);
        height: calc(100% + 0.8rem);
        top: calc(0% - 0.4rem);
        opacity: 1;
      }
    }
  }
}

.breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;

  .dot {
    width: 6px;
    height: 6px;
    background-color: $white;
    border-radius: 6px;
  }
}
