@use "./../../styles/variables.scss" as *;

.all-speakers-list {
  // max-height: 60vh;
  overflow-y: scroll;
  padding: 2rem 2rem 8rem 2rem;

  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 2px;
    border: 3px solid $black;
  }
}
