@use "../../styles/variables.scss" as *;

.modalContainerRoot {
  top: 0px;
  width: 100vw;
  left: 0;
  position: fixed;
  z-index: 200;

  // Modal global styling
  :global #modal-container-root {
    &:has(.hidden-modal) {
      .modal-backdrop {
        opacity: 0;
        backdrop-filter: blur(0px);
      }
    }
    &:has(.opened-modal) {
      .modal-backdrop {
        opacity: 1;
        backdrop-filter: blur(2px);
      }
    }
    &:has(.closed-modal) {
      .modal-backdrop {
        opacity: 0;
        backdrop-filter: blur(0px);
      }
    }
  }

  :global .modal-backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    user-select: none;
    background: rgba(0, 0, 0, 0.8);
    z-index: 201;
    backdrop-filter: blur(2px);
    transition: opacity 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
  }

  :global .modal-wrapper {
    position: absolute;
    width: 100%;
    height: 100vh;
    // overflow: scroll;
  }

  :global .modal-content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 90vh;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    // border-radius: 12px;
    width: fit-content;
    z-index: 202;

    // max-height: 85vh;
    // overflow-y: scroll;

    background-color: $primary;
    clip-path: polygon(0% 4%, 100% 0%, 100% 100%, 0% 96%);
    color: $white;
    ::selection {
      background-color: $white;
      color: $primary;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      clip-path: polygon(0% 2%, 100% 0%, 100% 100%, 0% 98%);
      top: 0px;
      transform: translate(-50%, 2rem);
    }

    &.opened-modal {
      pointer-events: all;
      animation: openModal 0.3s ease-in-out forwards;
    }

    &.closed-modal {
      pointer-events: none;
      animation: closeModal 0.3s ease-in-out forwards;
    }

    &.hidden-modal {
      pointer-events: none;
      opacity: 0;
    }

    &.allSpeakers-modal {
      clip-path: polygon(0% 4%, 100% 0%, 100% 100%, 0% 96%);
      .modal-header {
        padding-top: 3rem !important;
      }
      .modal-content {
        padding: 0 !important;
      }
      .modal-action-area {
        z-index: 999;
        position: absolute;
        bottom: 0;
      }
    }

    .modal-header {
      padding-top: 1.5rem !important;
      position: relative;
      white-space: normal;

      .close-modal {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        user-select: none;
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(270deg, rgba($white, 1) 50%, rgba($white, 0) 100%);
          z-index: -1;
        }
      }
      // box-shadow: 0px 1px 0px 0px $white, 0px 0px 12px 8px rgb(38 38 38 / 8%);

      &.confirm-header {
        // box-shadow: 0px 1px 0px 0px $white, 0px 0px 12px 8px rgb(38 38 38 / 8%);
      }
      &.error-header {
        // box-shadow: 0px 1px 0px 0px $alert, 0px 0px 12px 8px rgb(38 38 38 / 8%);
      }
      &.success-header {
        box-shadow: 0px 1px 0px 0px $success, 0px 0px 12px 8px rgb(38 38 38 / 8%);
      }
      &.warning-header {
        box-shadow: 0px 1px 0px 0px $alert, 0px 0px 12px 8px rgb(38 38 38 / 8%);
      }
      &.onlyCancel-header {
        box-shadow: 0px 1px 0px 0px $gray, 0px 0px 12px 8px rgb(38 38 38 / 8%);
      }
      &.blocker-header,
      .userTypeSelector-header {
        box-shadow: 0px 1px 0px 0px $gray, 0px 0px 12px 8px rgb(38 38 38 / 8%);
      }
      &.withValidation-header,
      .userTypeSelector-header {
        box-shadow: 0px 1px 0px 0px $lt-gray, 0px 0px 12px 8px rgb(38 38 38 / 8%);
      }
    }

    &.modal-narrow {
      width: clamp(270px, 85vw, 400px);
    }

    &.modal-regular {
      width: clamp(270px, 85vw, 500px);
    }

    &.modal-wide {
      width: clamp(270px, 85vw, 600px);
    }

    &.modal-wider {
      width: clamp(270px, 85vw, 800px);
    }

    &.modal-widest {
      width: 90%;
    }

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      color: $white;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 2px;
      border: 3px solid $black;
    }
  }
}
