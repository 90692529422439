@use "./../../styles/variables.scss" as *;

.left-big-shape-container {
  height: 100vh;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding-top: 4rem;
    height: 100%;
  }
}

.title-image-container {
  z-index: 1;
  img {
    border-radius: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    width: min(420px, 30vw);
    user-select: none;
    pointer-events: none;
    border: 1rem solid $primary;
    box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.5);

    @media (max-width: map-get($grid-breakpoints, lg)) {
      border: 0.5rem solid $primary;
      width: min(420px, 30vw);
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      width: min(420px, 60vw);
    }
  }
}

.description-shape-1 {
  position: relative;
  color: $white;
  max-width: 60%;

  &::after {
    content: "";
    position: absolute;
    width: 75%;
    left: -10%;
    height: 120%;
    top: 0%;
    z-index: -1;
    background-color: $primary;
    clip-path: polygon(0% 0%, 100% 5%, 94% 75%, 7% 95%);
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    max-width: 85%;

    &::after {
      width: 120%;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    max-width: 100%;
    // padding-left: 1rem;
    // padding-right: 1rem;

    &::after {
      // width: 96%;
      // left: 2%;
      display: none;
    }
  }
}

.interview-media-container {
  aspect-ratio: 16/9;
}
